<template>
  <div class="Address ">
    <ul class="add">
      <li>
        <el-button
          type="primary"
          @click="toAdd"
        >新增地址</el-button>
      </li>
      <li>您已创建{{addressList.length}} 个收货地址，最多可创建25 个</li>
    </ul>
    <p
      style="line-height:45px;text-align:center"
      v-if='!addressList.length'
    > 您还没添加收货地址 </p>
    <div
      class="list "
      v-for="(item, index) in addressList"
      :key="index"
    >
      <div class="top">
        <ul>
          <li>{{ item.receiver }}</li>
          <li>{{ (item.get_region||{}).name }}</li>
          <li><i
              class="default"
              v-if='item.status == 1'
            > 默认地址</i></li>

        </ul>
        <ol class="themefont">
          <li @click="delect(item)">删除</li>
          <li @click="toEdit(item)">编辑</li>
          <li @click="defaultAddress(item.id)">设为默认地址</li>

        </ol>
      </div>
      <el-form
        label-width="80px"
        style='width:100%'
      >
        <el-form-item label="收货人">
          <span> {{ item.receiver }}</span>
        </el-form-item>
        <el-form-item label="所在地区">
          <span>{{(item.get_region||{}).name+' '+(item.getcity||{}).name+' '+(item.getarea||{name:''}).name+' '+(item.getstreet||{name:''}).name}} </span>

        </el-form-item>
        <el-form-item label="地址">
          <span> {{ item.address }}</span>
        </el-form-item>
        <el-form-item label="电话">
          <span> {{ item.tel }}</span>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      title="添加地址"
      :visible.sync="dialogVisible_add"
      width="70%"
    >

      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="140px"
        class="ruleForm"
      >

        <el-form-item
          label="收货人"
          prop="receiver"
        >
          <el-input v-model="ruleForm.receiver" />
        </el-form-item>

        <el-form-item
          label="联系电话"
          prop="tel"
        >
          <el-input v-model="ruleForm.tel" />
        </el-form-item>
        <el-form-item
          label="地区"
          prop="city_id"
        >
          <el-select
            style="width:25%"
            v-model="ruleForm.province_id"
            filterable
            placeholder="请选择"
            @change="getCity(true)"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>

          <el-select
            style="width:25%"
            v-model="ruleForm.city_id"
            filterable
            placeholder="请选择"
            @change="getArea(true)"
          >
            <el-option
              v-for="item in options2"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>

          <el-select
            style="width:25%"
            v-model="ruleForm.area_id"
            filterable
            placeholder="请选择"
            @change="getStreet(true)"
          >
            <el-option
              v-for="item in options3"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>

          <el-select
            style="width:25%"
            v-model="ruleForm.street_id"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in options4"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="地址"
          prop="address"
        >
          <el-input v-model="ruleForm.address" />
        </el-form-item>
        <el-form-item
          label="设为默认地址"
          prop=""
        >
          <el-switch
            :active-value="1"
            :inactive-value="0"
            v-model="ruleForm.status"
            active-color="#13ce66"
            inactive-color="#eee"
          >
          </el-switch>

        </el-form-item>
      </el-form>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible_add = false">取 消</el-button>
        <el-button
          type="primary"
          @click="add_submitForm('ruleForm')"
        >提交</el-button>
      </span>

    </el-dialog>
    <el-dialog
      title="编辑地址"
      :visible.sync="dialogVisible"
      width="70%"
    >

      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="140px"
        class="ruleForm"
      >

        <el-form-item
          label="收货人"
          prop="receiver"
        >
          <el-input v-model="ruleForm.receiver" />
        </el-form-item>

        <el-form-item
          label="联系电话"
          prop="tel"
        >
          <el-input v-model="ruleForm.tel" />
        </el-form-item>
        <el-form-item
          label="地区"
          prop="city_id"
        >
          <el-select
            style="width:25%"
            v-model="ruleForm.province_id"
            filterable
            placeholder="请选择"
            @change="getCity(true)"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>

          <el-select
            style="width:25%"
            v-model="ruleForm.city_id"
            filterable
            placeholder="请选择"
            @change="getArea(true)"
          >
            <el-option
              v-for="item in options2"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>

          <el-select
            style="width:25%"
            v-model="ruleForm.area_id"
            filterable
            placeholder="请选择"
            @change="getStreet(true)"
          >
            <el-option
              v-for="item in options3"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>

          <el-select
            style="width:25%"
            v-model="ruleForm.street_id"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in options4"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="地址"
          prop="address"
        >
          <el-input v-model="ruleForm.address" />
        </el-form-item>
        <el-form-item
          label="设为默认地址"
          prop=""
        >
          <el-switch
            :active-value="1"
            :inactive-value="0"
            v-model="ruleForm.status"
            active-color="#13ce66"
            inactive-color="#eee"
          >
          </el-switch>

        </el-form-item>

      </el-form>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="edit_submitForm('ruleForm')"
        >提交</el-button>
      </span>

    </el-dialog>

  </div>
</template>
<script>
// import VDistpicker from 'v-distpicker'
export default {
  data() {
    return {
      options: [],
      options2: [],
      options3: [],
      options4: [],

      ruleForm: {
        receiver: "",
        tel: "",
        province_id: "",
        city_id: "",
        area_id: "",
        street_id: "",
        address: "",
        zip_code: "0",
        id: "",
        status: 0
      },
      rules: {
        receiver: [
          {
            required: true,
            message: "请输入接收者姓名",
            trigger: "blur"
          }
        ],
        tel: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "blur"
          },
          {
            pattern: this.$validate.mobile,
            message: "请填写正确的电话",
            trigger: "change"
          }
        ],
        city_id: [
          {
            required: true,
            message: "请选择地区",
            trigger: "blur"
          }
        ],
        address: [
          {
            required: true,
            message: "请填写地址",
            trigger: "blur"
          }
        ]
      },

      addressList: [],
      dialogVisible_add: false,
      dialogVisible: false,
      id: "",
      showPopup: false,
      province: [],
      city: [],
      country: [],
      town: [],
      region: "",

      member_id: this.$store.state.member.id,
      receiver: "",
      tel: "",
      province_id: "",
      city_id: "",
      area_id: "",
      street_id: "",
      address: "",
      zip_code: "0"
      // status: 1
    };
  },
  methods: {
    getAddressID(id) {
      this.$get("home/address/" + id).then(res => {
        console.log("info", res);
        this.ruleForm.province_id = res.province_id;
        this.ruleForm.city_id = res.city_id;
        this.ruleForm.area_id = res.area_id;
        this.ruleForm.street_id = res.street_id;
        this.ruleForm.receiver = res.receiver;
        this.ruleForm.tel = res.tel;
        this.ruleForm.address = res.address;
        this.ruleForm.zip_code = res.zip_code;
        this.ruleForm.status = res.status;
        this.ruleForm.id = res.id;

        this.getProvince();
        this.getCity();
        this.getArea();
        this.getStreet();
      });
    },

    add_submitForm(formName) {
      let data = {
        member_id: this.$store.state.member.id,
        receiver: this.ruleForm.receiver,
        tel: this.ruleForm.tel,
        province_id: this.ruleForm.province_id,
        city_id: this.ruleForm.city_id,
        area_id: this.ruleForm.area_id || 0,
        street_id: this.ruleForm.street_id || 0,
        address: this.ruleForm.address,
        zip_code: false,
        status: this.ruleForm.status
      };
      console.log("data", data);
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$post("home/address", data).then(() => {
            this.dialogVisible_add = false;
            this.$message("添加成功");
            this.getAddress();
          });
        }
      });
    },
    edit_submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$put("home/address/" + this.ruleForm.id, {
            member_id: this.$store.state.member.id,
            receiver: this.ruleForm.receiver,
            tel: this.ruleForm.tel,
            province_id: this.ruleForm.province_id,
            city_id: this.ruleForm.city_id,
            area_id: this.ruleForm.area_id,
            street_id: this.ruleForm.street_id,
            address: this.ruleForm.address,
            zip_code: this.ruleForm.zip_code,
            status: this.ruleForm.status
          }).then(() => {
            this.dialogVisible = false;
            this.$message("编辑成功");
            this.getAddress();
          });
        }
      });
    },

    getProvince() {
      this.$post("home/regions/index", {
        level: 1,
        pid: 0
      }).then(res => {
        this.options = res;
      });
    },
    getCity(isChange) {
      if (isChange) {
        this.ruleForm.city_id = undefined;
        this.ruleForm.area_id = undefined;
        this.ruleForm.street_id = undefined;
        this.options2 = [];
        this.options3 = [];
        this.options4 = [];
      }
      this.$post("home/regions/index", {
        level: 2,
        pid: this.ruleForm.province_id
      }).then(res => {
        this.options2 = res;
      });
    },
    getArea(isChange) {
      if (isChange) {
        this.ruleForm.area_id = undefined;
        this.ruleForm.street_id = undefined;
        this.options3 = [];
        this.options4 = [];
      }
      this.$post("home/regions/index", {
        level: 3,
        pid: this.ruleForm.city_id
      }).then(res => {
        this.options3 = res;
      });
    },
    getStreet(isChange) {
      if (isChange) {
        this.ruleForm.street_id = undefined;
        this.options4 = [];
      }
      this.$post("home/regions/index", {
        level: 4,
        pid: this.ruleForm.area_id
      }).then(res => {
        this.options4 = res;
      });
    },
    defaultAddress(id) {
      console.log(" this.addressList", id);
      this.$put("home/address/" + id, {
        member_id: this.$store.state.member.id,
        status: 1
      }).then(res => {
        console.log("rssa", res);
        this.getAddress();
      });
    },
    chose(item) {
      this.$router.push({
        path: "/order/submit",
        query: { noDefault: true }
      });
      sessionStorage.setItem("address", JSON.stringify(item));
    },
    //新增地址
    toAdd() {
      this.ruleForm = {
        receiver: "",
        tel: "",
        province_id: "",
        city_id: "",
        area_id: "",
        street_id: "",
        address: "",
        zip_code: "0",

        status: 0
      };
      this.dialogVisible_add = true;
      this.getProvince();
    },
    //跳转编辑地址
    toEdit(item) {
      this.ruleForm = {
        receiver: "",
        tel: "",
        province_id: "",
        city_id: "",
        area_id: "",
        street_id: "",
        address: "",
        zip_code: "0",
        id: "",
        status: 0
      };
      this.dialogVisible = true;
      this.getAddressID(item.id);
    },

    delect(item) {
      this.$confirm("确认删除选中地址?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let id = item.id;
          this.$del("home/address/" + id).then(res => {
            console.log("adress", res);
            this.$message("删除成功");
            this.getAddress();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    //获取地址
    getAddress() {
      this.$get("home/address", { member_id: this.$store.state.member.id }).then(res => {
        console.log("res", res);

        this.addressList = res.items;
        if (this.addressList.length) {
          this.default_address = this.addressList.find(a => a.status == 1).id;
        }
      });
    }
  },
  components: {},
  created() {
    this.getAddress();
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/common";
@import "@/assets/styles/mixins";
.default {
  background: #e94c15;
  color: #fff;
  padding: 0 5px;
  border-radius: 5px;
}
::v-deep .ruleForm .el-form-item {
  padding: 15px;
}
::v-deep .ruleForm .el-form-item {
  padding: 15px;
}
.add::after {
  display: block;
  clear: both;
  content: "";
}
.add {
  line-height: 30px;
  margin-bottom: 20px;
  width: 100%;
  li {
    margin-right: 20px;
    float: left;
  }
}
::v-deep .el-form-item__label {
  line-height: 25px;
}
::v-deep .el-form-item__content {
  line-height: 25px;
}

.list {
  border: 1px solid #dedede;
  margin-bottom: 10px;
  width: 100%;

  padding: 20px;

  .top {
    @include space-between;

    ul {
      margin-bottom: 10px;
      width: 50%;

      li {
        display: inline-block;
        margin-right: 20px;

        font-weight: bold;
      }
    }
    ol {
      width: 50%;
      li:hover {
        cursor: pointer;
      }
      li {
        float: right;
        display: block;
        margin-left: 20px;
      }
    }
  }
}
.mine {
  height: 400px;

  .el-form-item {
    height: 70px;
    width: 410px;
  }
}
.mine .el-form-item:nth-child(3) {
  width: 750px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #e94c15;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  border: 1px solid #ccc;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.Btn {
  padding: 10px 20px;
  background-color: #5584ff;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}
.Btn:hover {
  background: rgb(83, 168, 255);
}
.CloBtn {
  padding: 10px 20px;
  background-color: #ccc;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}
.CloBtn:hover {
  background: rgb(83, 168, 255);
}
.Generated {
  margin-top: 20px;
  width: 1060px;
  height: 230px;
  padding: 5px;
  .el-table {
    border: 1px solid #ccc;
    .el-table th.is-leaf {
      border: 1px solid #ccc;
    }
  }
}
.el-input--suffix {
  width: 50px;
}
ul {
  .el-icon-delete {
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: 20px;
  }
  .el-icon-delete:hover {
    color: #e94c15;
  }
  .el-icon-edit {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
  }
  .el-icon-edit:hover {
    color: #e94c15;
  }
}
.NewAddress {
  //  padding:5px 10px ;
  border: 1px solid #222;
  text-align: center;
  width: 120px;
  height: 40px;
  line-height: 40px;
  //  margin:25px 0 0 -20px;
  font-size: 14px;
  border-radius: 3px;
  //  position: absolute;
  //  top:140px;
  //  left: 20px;
}
.NewAddress:hover {
  background-color: #f2f3f7;
}
.cur {
  background-image: url(//img.alicdn.com/tfs/TB1OVRCRpXXXXaMXFXXXXXXXXXX-237-106.png);
}
.login {
  position: fixed;
  font-size: 24px;
  height: 450px;
  width: 500px;
  background-color: #fff;
  border-radius: 0.25rem;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
  z-index: 1000;
  padding: 10px 0 0 30px;
}
.over {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.7; //透明度为70%
  filter: alpha(opacity=70);
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #111111;
}
.el-input__inner {
  padding: 0 !important;
  text-indent: 15px !important;
  border-radius: 0 !important;
}
.el-select-dropdown__item {
  padding: 0 15px !important;
}
</style>
<style lang="scss">
.distpicker-address-wrapper {
  select {
    width: 100px;
  }
}
.el-select-dropdown__list {
  padding-left: 10px;
}
</style>
